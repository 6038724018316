<template>
  <div>
    <v-row>
      <v-col cols="12" md="3" lg="3"></v-col>
      <v-col cols="12" md="6" lg="6">
        <div class="text-center">
          <v-tabs class="lfont">
            <v-tab @click="changeTabs(0)">{{
              $t("employee_ot.lineManager")
            }}</v-tab>
            <v-tab @click="changeTabs(1)">{{ $t("employee_ot.hr") }}</v-tab>
          </v-tabs>
        </div>
      </v-col>
    </v-row>
    <div v-if="hrActive == 'true' || lmActive == 'true'">
      <div v-if="activeIndex === 0">
        <LmApprove />
      </div>
      <div v-if="activeIndex === 1">
        <HrApprove />
      </div>
    </div>
  </div>
</template>

<script>
import LmApprove from "@/views/MainEmployees/EmployeeApproveOT/LmApprove";
import HrApprove from "@/views/MainEmployees/EmployeeApproveOT/HrApprove";

export default {
  components: {
    LmApprove,
    HrApprove,
  },
  data() {
    return {
      activeIndex: 0,
      lmActive: false,
      hrActive: false,
    };
  },
  methods: {
    changeTabs(index) {
      this.activeIndex = index;
    },
  },
  created() {
    this.lmActive = localStorage.getItem("user_role_lm");
    this.hrActive = localStorage.getItem("user_role_hr");
    if (this.hrActive == true) {
      this.activeIndex = 1;
    } else {
      this.activeIndex = 0;
    }
  },
};
</script>

<style scoped>
.text-center {
  text-align: center !important;
}
</style>
