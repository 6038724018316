<template>
  <div>
    <v-container>
      <v-row class="mt-4">
        <v-col cols="12">
          <h3 class="lfont">
            {{ $t("employee_ot.title_approve") }}
          </h3>
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <v-col cols="12">
          <v-row>
            <v-col cols="6" sm="6" md="5" lg="5">
              <label class="label-input">{{
                $t("employee_ot.search_title")
              }}</label>
              <v-text-field
                class="btn btn-color btn-border btn-border-color"
                outlined
                dense
                v-model="searchItem"
                prepend-inner-icon="mdi-magnify"
                :placeholder="$t('Search.search')"
                @keypress.enter="searchFilterItem"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <label class="label-input">Status</label>
              <v-select
                :items="listStatus"
                items-text="listStatus.text"
                items-value="listStatus.value"
                dense
                outlined
                v-model="status"
                @change="searchFilterItemByStatus()"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="listEmployeeOt.length > 0">
        <v-col
          cols="12"
          md="3"
          sm="12"
          lg="3"
          class="mb-4"
          v-for="(item, idx) in listEmployeeOt"
          :key="idx"
        >
          <v-card class="mx-auto">
            <v-card-text>
              <v-row class="lfont">
                <v-col cols="12" sm="12" md="12" class="mb-3 pt-3">
                  <div class="d-flex justify-space-between">
                    <span>{{ $t("employee_ot.table.emp_number") }}</span>
                    <span>{{ item.employee.emp_number }}</span>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="mb-3 pt-3">
                  <div class="d-flex justify-space-between">
                    <span>{{ $t("employee_ot.table.emp_name") }}</span>
                    <span>{{ item.employee.name }}</span>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="mb-3 pt-3">
                  <div class="d-flex justify-space-between">
                    <span>{{ $t("employee_ot.table.date") }}</span>
                    <span>{{ item.date }}</span>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="mb-3">
                  <div class="d-flex justify-space-between">
                    <span>{{ $t("employee_ot.table.emp_run_time") }}</span>
                    <span>{{ item.check_in }} - {{ item.check_out }}</span>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="mb-3">
                  <div class="d-flex justify-space-between">
                    <span>{{ $t("employee_ot.table.qty_hour") }}</span>
                    <span>
                      <span v-if="item.qty.hour > 0">
                        {{ item.qty.hour }}
                        {{ $t("employee_ot.table.hour") }}
                      </span>

                      <span v-if="item.qty.minute > 0">
                        {{ item.qty.minute }}
                        {{ $t("employee_ot.table.minute") }}
                      </span>
                    </span>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="mb-3">
                  <div class="d-flex justify-space-between">
                    <span>{{ $t("employee_ot.table.status") }}</span>
                    <span>
                      <span
                        v-if="item.hr_approve_status == 'pending'"
                        style="color: orange"
                      >
                        {{ item.hr_approve_status }}
                      </span>
                      <span
                        v-else-if="item.hr_approve_status == 'approve'"
                        style="color: green"
                      >
                        {{ item.hr_approve_status }}
                      </span>
                      <span v-else style="color: red">
                        {{ item.hr_approve_status }}
                      </span>
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mx-4"></v-divider>
            <v-card-actions class="lfont">
              <span v-if="item.hr_approve_status == 'pending'">
                <v-btn
                  text
                  color="success accent-4"
                  @click="modalHrApproveOt(item.id)"
                  style="font-family: Noto Sans Lao"
                >
                  {{ $t("employee_ot.approve") }}
                </v-btn>
                <v-btn
                  text
                  style="font-family: Noto Sans Lao"
                  color="error accent-4"
                  @click="modalHrRejectOt(item.id)"
                >
                  {{ $t("employee_ot.reject") }}
                </v-btn>
              </span>
              <span v-else>
                <v-btn
                  disabled
                  style="font-family: Noto Sans Lao"
                  text
                  color="success accent-4"
                  @click="onEdit(item.id)"
                >
                  {{ $t("employee_ot.approve") }}
                </v-btn>
                <v-btn
                  disabled
                  text
                  style="font-family: Noto Sans Lao"
                  color="error accent-4"
                  @click="onDeleted(item.id)"
                >
                  {{ $t("employee_ot.reject") }}
                </v-btn>
              </span>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <defaultTableNoResult v-else />

      <!-- <defaultTableNoResult v-else /> -->
      <Pagination
        v-if="pagination.total_pages > 1"
        :pagination="pagination"
        :offset="offset"
        @paginate="fetchEmployeeOt"
      >
      </Pagination>
      <Loading v-if="isLoading" />

      <HrApproveOt
        @success="fetchEmployeeOt"
        @close="dialog = false"
        :ot_id="ot_id"
        :dialog="dialog"
      />

      <HrRejectOt
        @success="fetchEmployeeOt"
        @close="dialogR = false"
        :ot_id="ot_id"
        :dialogR="dialogR"
      />
    </v-container>
  </div>
</template>

<script>
import Pagination from "../../../components/Paginate/Pagination";
import defaultTableNoResult from "@/components/defaultTableNoResult";
import Loading from "@/components/Loading";
import HrApproveOt from "@/views/MainEmployees/EmployeeApproveOT/HrModal/HrApproveOt";
import HrRejectOt from "@/views/MainEmployees/EmployeeApproveOT/HrModal/HrRejectOt";
export default {
  components: {
    Pagination,
    defaultTableNoResult,
    Loading,
    HrApproveOt,
    HrRejectOt,
  },

  data() {
    return {
      offset: 10,
      pagination: {},
      per_page: 10,
      isLoading: true,
      listEmployeeOt: [],
      listStatus: [
        {
          text: "ALL",
          value: "",
        },
        {
          text: "Pending",
          value: "pending",
        },
        {
          text: "Approve",
          value: "approve",
        },
        {
          text: "Reject",
          value: "reject",
        },
      ],
      searchItem: "",
      ot_id: "",
      isLoading: true,
      dialog: false,
      dialogR: false,
      status: "",
    };
  },
  methods: {
    searchFilterItem() {
      this.fetchEmployeeOt();
      this.pagination.current_page = 1;
    },
    searchFilterItemByStatus() {
      this.fetchEmployeeOt();
      this.pagination.current_page = 1;
    },
    fetchEmployeeOt() {
      this.isLoading = true;
      this.$axios
        .get(`hr/request/ot`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.searchItem,
            status: this.status,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.listEmployeeOt = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.listEmployeeOt.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
          this.isLoading = false;
          // console.log(res);
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    modalHrApproveOt(ot_id) {
      this.dialog = true;
      this.ot_id = ot_id;
    },
    modalHrRejectOt(rejectOt_id) {
      this.dialogR = true;
      this.ot_id = rejectOt_id;
    },
  },
  created() {
    this.fetchEmployeeOt();
  },
};
</script>

<style scoped lang="scss">
.search {
  input {
    padding: 13px;
  }
}
.section-btn {
  .btn-export {
    background: rgb(67, 184, 69);
    color: white;
  }
  .btn-import {
    background: rgb(111, 153, 231);
    color: white;
  }
  .btn-create {
    color: rgb(255, 255, 255);
    background: #5dade2;
    font-family: $font-family;
  }
}
.fullTime {
  display: flex;
  align-items: center;

  i {
    color: #70f7bd;
    font-size: 8px;
    padding: 0 5px;
  }
}

.partTime {
  display: flex;
  align-items: center;

  i {
    color: #f5b556;
    font-size: 8px;
    padding: 0 5px;
  }
}

.employee-name {
  font-size: 16px;
  font-family: $font-family;
  font-weight: bold;
  color: rgb(0, 0, 0, 0.8);

  span {
    margin: 0 2px;
  }
}

.employee-position {
  font-size: 12px;
  font-family: $font-family;
  font-weight: lighter;
  color: #919191;

  i {
    padding: 0 5px;
  }
}

.position {
  span {
    position: relative;
    border-radius: 30px;
    font-size: 14px;
    // padding: 3px 18px;
    font-family: $font-family;
    letter-spacing: 1px;
    text-transform: capitalize;
    //background-color: rgba(30, 144, 255,0.4);
    color: #606060;
    font-weight: 300;

    .length-position {
      position: absolute;
      font-size: 12px;
      padding: 0.5px 6px;
      right: -35px;
      top: 0px;
      color: white;
      background-color: rgba(30, 144, 255, 0.4);
      cursor: pointer;
      transition: ease-out 0.2s;
    }

    .length-position:hover {
      transform: translateY(-10%);
    }
  }
}

.tooltip-la {
  font-family: $font-family;
}

.employee-exit {
  background-color: rgba(127, 140, 141, 0.1);
}

.leave-soon {
  background-color: rgba(254, 202, 87, 1);
  width: min-content;
  margin-left: 40px;
  color: #ffffff;
  padding: 2px 8px;
  font-size: 13px;
  border-radius: 30px;
  cursor: pointer;
}
</style>
